<template>
  <div class='equipment'>
    <el-tabs v-model="activeName">
      <el-tab-pane label="单位管理" name="first">
        <div class="content_box">
          <unit />
        </div>
      </el-tab-pane>
      <el-tab-pane label="用户管理" name="second">
        <div class="content_box">
          <user />
        </div>
      </el-tab-pane>
      <el-tab-pane label="算法管理" name="third" v-if="isShow">
        <div class="content_box">
          <algorithm />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Unit from './components/unit.vue'
import User from './components/user.vue'
import Algorithm from './components/algorithm.vue'
import { mapGetters } from 'vuex'
export default {
  components: { Unit, User, Algorithm },
  data () {
    return {
      activeName: 'first',
      isShow: true
    };
  },
  created () {
    if (sessionStorage.getItem('tabNumber')) {
      this.activeName = sessionStorage.getItem('tabNumber')
    } else {
      this.activeName = 'first'
    }

    this.isShow = this.domainName === '庆元县' || this.domainName === '温州市' || this.domainName === '上城区' ? true : false
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['domainName'])
  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.equipment {
  ::v-deep .el-tabs__nav-wrap::after {
    background: none;
  }
  ::v-deep .el-tabs__item {
    color: #fff;
    // .is
  }
  ::v-deep .el-tabs__header {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    .is-active {
      color: #1890ff;
    }
  }
  .content_box {
    // margin: 30px 0 30px 30px;
    padding: 10px 20px;
    height: 78vh;
    background: url("../../assets/body.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
