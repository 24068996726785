<template>
  <div class='algorithm'>
    <div>
      <h3>算法管理</h3>
      <div class="btn">
        <el-button class="btn_info" style="margin-right:18px" @click="addVisible = true">添加算法</el-button>
      </div>
    </div>
    <base-table ref="myTable" :filterList="filterList" :columns="columns" :tableList="tableData" @queryForm="queryForm"></base-table>
    <base-pagination class="basepagination" v-show="total>10" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :layout="layout" @pagination="getList"></base-pagination>
    <!-- 添加算法弹框 -->
    <el-dialog title="添加算法" :visible.sync="addVisible" width="25%" :lock-scroll="false">
      <el-form :model="algorithmForm" :rules="rules" ref="algorithmForm" :hide-required-asterisk="true">
        <el-form-item prop="name" label="算法名称：">
          <el-input v-model="algorithmForm.name" placeholder="请输入算法名称" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="imageFrequency" label="裁图间隔：">
          <el-input v-model="algorithmForm.imageFrequency" placeholder="请输入裁图间隔" size="small" type="number">
          </el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" :loading="loginLoading" @click="submitForm('algorithmForm')">添加</el-button>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('algorithmForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { addAlgorithm } from '@/api'
export default {
  data () {
    return {
      layout: 'prev, pager, next',
      total: 10,
      tableData: [],
      totalData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        { attrs: { type: 'index', label: '序号', align: 'center' } },
        { attrs: { prop: 'name', label: '算法名称', align: 'center' } },
        // {
        //   attrs: { label: '操作', align: 'center' },
        //   btns: row => [
        //     { iconclass: 'el-icon-delete', msg: '您确认要删除该算法吗？', listeners: { click: () => this.ensureDelete(row) }, popover: { attrs: { width: 80 } } }
        //   ]
        // }
      ],
      addVisible: false,
      algorithmForm: {
        name: '',
        imageFrequency: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入算法名称', trigger: 'blur' },
          // { required: true, message: '请输入算法名称', trigger: 'blur' }
          { required: true, validator: this.$validator.special, trigger: 'blur' }
        ],
        imageFrequency: [
          { required: true, message: '请输入裁图间隔', trigger: 'blur' }
        ]
      },
      loginLoading: false
    };
  },
  created () {
    this.getList()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['algorithmList']),
    filterList () {
      const arr = [
        {
          name: '',
          type: 'input',
          attrs: {
            style: { width: '250px' },
            size: 'small',
            placeholder: '搜索算法名称'
          },
          prop: 'name',
          slot: 'append',
          slotType: 'button',
          icon: 'el-icon-search'
        }
      ]
      return arr
    }
  },
  methods: {
    init (val) {
      // sitelist(this.$tools.concatParams(val)).then(res => {
      //   this.totalData = res.data
      //   this.getList(this.totalData)
      // })
      this.$store.dispatch('getAlgorithm', val.name).then(() => {
        this.getList()
      })
    },
    getList (val) {
      if (val) {
        this.totalData = val
      } else {
        this.totalData = this.algorithmList
      }
      this.tableData = []
      if (this.totalData.length <= 10) {
        this.tableData = this.totalData
        return
      }
      var start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      var end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }

      for (let i = start; i < end; i++) {
        this.tableData.push(this.algorithmList[i])
      }
    },
    ensureDelete (val) {
      console.log(val)
      // this.tableData[index].visible = false
    },
    submitForm () {
      this.$refs.algorithmForm.validate((valid) => {
        if (valid) {
          addAlgorithm(this.$tools.concatParams(this.algorithmForm)).then(() => {
            this.$store.dispatch('getAlgorithm').then(() => {
              this.getList()
              this.$message.success('算法添加成功')
              this.addVisible = false
            })

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    queryForm (val) {
      // console.log(val)
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 10
      this.init(val)
      // if (val.name){

      // }
    }
  }
};
</script>

<style scoped lang="scss">
.algorithm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  h3 {
    color: #30c6fe;
    font-size: 20px;
    font-weight: bold;
  }
  .btn {
    position: absolute;
    right: 10px;
    top: 5%;
  }
  .selectbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
    ::v-deep .el-input-group {
      background: none;
      // width: 100%;
      .el-input__inner {
        background: none;
        border: 1px solid #30c6fe;
        color: #fff;
      }
      .el-input-group__append {
        background: #1a6899;
        border: 1px solid #30c6fe;
        border-left: none;
        // padding: 0 20px;
        .el-icon-search {
          color: #fff;
        }
      }
    }
  }
  ::v-deep .el-dialog {
    .el-dialog__body {
      padding: 0 30px 20px;
    }
  }
  ::v-deep .filter-box {
    margin: 0;
  }
  ::v-deep .filter-wrapper {
    background: none;
    padding: 0;
    .el-form-item__label {
      color: #fff;
    }
    .el-form-item__content {
      .el-input__inner {
        background: none;
        border: 1px solid #30c6fe;
        color: #fff;
      }
      .el-input-group__append {
        background: #1a6899;
        border: 1px solid #30c6fe;
        border-left: none;
        // padding: 0 20px;
        .el-icon-search {
          color: #fff;
        }
      }
    }
  }
  ::v-deep .el-table {
    background: none;
  }
  ::v-deep .table-wrapper {
    background: none;
    height: 62vh;
    padding: 0;
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background: #174e92;
    }
  }
  .basepagination {
    text-align: right;
  }
}
</style>
