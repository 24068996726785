<template>
  <div class='user'>
    <div>
      <h3>用户管理</h3>
      <div class="btn">
        <el-button class="btn_info" style="margin-right:18px" @click="addUser">添加用户</el-button>
      </div>
    </div>
    <base-table ref="myTable" :filterList="filterList" :columns="columns" :tableList="tableData" @queryForm="queryForm"></base-table>
    <base-pagination class="basepagination" v-show="total>10" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :layout="layout" @pagination="getList"></base-pagination>
  </div>
</template>

<script>
import { getUserList, deleteUser } from '@/api'
export default {
  data () {
    return {
      layout: 'prev, pager, next',
      tableData: [],
      totalList: [],
      total: 10,
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        { attrs: { prop: 'username', label: '用户名', align: 'center' } },
        { attrs: { prop: 'name', label: '姓名', align: 'center' } },
        { attrs: { prop: 'phone', label: '联系方式', align: 'center' } },
        { attrs: { prop: 'mail', label: '邮箱', align: 'center' } },
        {
          attrs: { label: '创建时间', prop: 'gmtcreate', align: 'center' }
        },
        {
          attrs: { label: '操作', align: 'center' },
          btns: row => [
            { iconclass: 'el-icon-delete', msg: '您确认要删除该用户吗？', listeners: { click: () => this.ensureDelete(row) }, popover: { attrs: { width: 80 } } }
          ]
        }
      ]
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    filterList () {
      const arr = [
        {
          name: '',
          type: 'input',
          attrs: {
            style: { width: '250px' },
            size: 'small',
            placeholder: '搜索用户姓名'
          },
          prop: 'name',
          slot: 'append',
          slotType: 'button',
          icon: 'el-icon-search'
        }
      ]
      return arr
    }
  },
  methods: {
    init () {
      getUserList(this.$tools.concatParams(this.queryParams)).then(res => {
        this.totalList = res.data.data
        this.total = res.data.data.length
        this.getList()
      })
    },
    getList () {
      this.tableData = []
      var start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      var end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }

      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
    },
    queryForm (val) {
      this.queryParams = {
        ...val,
        pageNum: 1,
        pageSize: 10,
      }
      this.init()
    },
    ensureDelete (val) {
      // console.log(val)
      deleteUser(val.id).then(() => {
        this.$message.success('用户删除成功')
        this.init()
      })
    },
    addUser () {
      this.$router.push('/system/adduser')
      sessionStorage.setItem('tabNumber', 'second')
    }
  }
};
</script>

<style scoped lang="scss">
.user {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  h3 {
    color: #30c6fe;
    font-size: 20px;
    font-weight: bold;
  }
  .btn {
    position: absolute;
    right: 10px;
    top: 5%;
  }
  ::v-deep .filter-box {
    margin: 0;
  }
  ::v-deep .filter-wrapper {
    background: none;
    padding: 0;
    .el-form-item__label {
      color: #fff;
    }
    .el-form-item__content {
      .el-input__inner {
        background: none;
        border: 1px solid #30c6fe;
        color: #fff;
      }
      .el-input-group__append {
        background: #1a6899;
        border: 1px solid #30c6fe;
        border-left: none;
        // padding: 0 20px;
        .el-icon-search {
          color: #fff;
        }
      }
    }
  }
  ::v-deep .el-table {
    background: none;
  }
  ::v-deep .table-wrapper {
    background: none;
    height: 62vh;
    padding: 0;
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background: #174e92;
    }
  }
  .basepagination {
    text-align: right;
  }
}
</style>
