<template>
  <div class='unit'>
    <div>
      <h3>单位管理</h3>
      <div class="selectbox">
        <div>
          <el-input placeholder="搜索单位名称" v-model="queryParams.key" class="input-with-select" size="small" @input="search">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <div class="btn">
          <el-button class="btn_info" style="margin-right:18px" @click="addUnit">添加单位</el-button>
        </div>
      </div>
      <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">

        <el-table :data="form.tableData" stripe style="width: 100%" :cell-style="rowClass" :header-cell-style="headClass" @filter-change="filterType">
          <el-table-column prop="sitename" label="单位名称" min-width="220">
            <template slot-scope="scope">
              <el-form-item v-if="scope.row.editShow" :prop="'tableData.' + scope.$index + '.sitename'" :rules="rules.sitename">
                <el-input v-model="scope.row.sitename" size="small" />
              </el-form-item>
              <span v-else>{{scope.row.sitename}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="sceneName" label="单位类型" :filter-multiple="false" :filters="typeList" column-key="type">
            <template slot-scope="scope">
              <el-form-item v-if="scope.row.editShow" :prop="'tableData.' + scope.$index + '.sceneId'" :rules="rules.sceneId">
                <el-select v-model="scope.row.sceneId" placeholder="请选择单位类型" size="small">
                  <el-option v-for="item in sceneList" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>

              <span v-else>{{scope.row.sceneName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="adminName" label="负责人姓名">
            <template slot-scope="scope">

              <el-form-item v-if="scope.row.editShow" :prop="'tableData.' + scope.$index + '.adminName'" :rules="rules.adminName">
                <el-input v-model="scope.row.adminName" size="small" />
              </el-form-item>

              <span v-else>{{scope.row.adminName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="联系电话">
            <template slot-scope="scope">
              <el-form-item v-if="scope.row.editShow" :prop="'tableData.' + scope.$index + '.phone'" :rules="rules.phone">
                <el-input v-model="scope.row.phone" size="small" />
              </el-form-item>
              <span v-else>{{scope.row.phone}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="cameraQuantity" label="设备数量">
            <template slot-scope="scope">
              <span>{{ scope.row.onlineAmount }}</span><span>/{{scope.row.cameraQuantity}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="详细地址" min-width="160">
            <template slot-scope="scope">
              <el-form-item v-if="scope.row.editShow" :prop="'tableData.' + scope.$index + '.address'" :rules="rules.address">
                <el-input v-model="scope.row.address" size="small" />
              </el-form-item>
              <span v-else>{{scope.row.address}}</span>
            </template>
          </el-table-column>

          <el-table-column prop="gmtcreate" label="创建时间">
          </el-table-column>
          <el-table-column label="房间管理">
            <template slot-scope="{row}">
              <el-button type="text" @click="$router.push({path:'/system/roommanagement',query:{siteid:row.id}})">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="action" label="操作" width="100">
            <template slot-scope="scope">
              <i class="el-icon-edit" @click="edit(scope.$index,scope.row)" style="color:#1890ff;margin-right:10px;cursor: pointer;" v-if="!scope.row.editShow"></i>
              <div v-else class="btns">
                <span @click="save(scope.$index,scope.row)">保存</span>
                <span @click="cancel(scope.$index,scope.row)">取消</span>
              </div>
              <el-popover placement="bottom-start" width="180" v-model="scope.row.visible">
                <p>您确认要删除该单位吗？</p>
                <div style="text-align: right; margin: 10px 0 0 0">
                  <el-button size="mini" type="text" @click="scope.row.visible = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="ensureDelete(scope.row.id),scope.row.visible = false">确定</el-button>
                </div>
                <i class="el-icon-delete" slot="reference"></i>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <pagination v-show="total>10" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" :layout="layout" />
  </div>
</template>

<script>
import { getUnitList, deleteUnit, editUnit } from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    var string = (rule, value, callback) => {
      // eslint-disable-next-line
      const reg = new RegExp(/[`~!@#$%^&*_\+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*——\+={}|《》？：“”【】、；‘'，。、]/im, 'g')
      if ((value && reg.test(value))) {
        callback(new Error('输入框不能输入特殊字符'))
      } else {
        callback()
      }
    }
    return {
      layout: 'prev, pager, next',

      totalList: [],
      total: 10,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        // key: null
      },
      selectoptions: [],
      form: {
        tableData: [],
      },
      rules: {
        sitename: [
          { required: true, message: '请输入单位名称', trigger: 'change' },
          { required: true, validator: string, trigger: 'change' }
        ],
        sceneId: [
          { required: true, message: '请选择单位类型', trigger: 'change' },
        ],
        adminName: [
          { required: true, message: '请输入负责人姓名', trigger: 'change' },
          { required: true, validator: this.$validator.username, trigger: 'change' }
        ],
        phone: [
          { required: true, validator: this.$validator.phone, trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'change' },
          { required: true, validator: string, trigger: 'change' }
        ]
      }
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['sceneList']),
    typeList () {
      let arr = []
      this.sceneList.map(el => {
        arr.push({
          text: el.name, value: el.id
        })
      })
      return arr
    }
  },
  methods: {
    init () {
      getUnitList(this.$tools.concatParams(this.queryParams)).then(res => {
        this.totalList = res.data.data
        this.total = res.data.data.length
        this.getList()
      })
    },
    getList () {
      this.form.tableData = []
      var start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      var end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }

      for (let i = start; i < end; i++) {
        this.form.tableData.push(this.totalList[i])
      }
    },
    // 搜索框
    search () {
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 10
      this.init()
      // console.log(this.queryParams.key)
    },
    edit (index, row) {
      // console.log(index, row)
      // console.log(sessionStorage.getItem('oldindex'))
      // console.log(sessionStorage.getItem('oldRow'))
      // row.editShow = !row.editShow
      if (sessionStorage.getItem('oldindex')) {
        this.$set(this.form.tableData[sessionStorage.getItem('oldindex')], 'editShow', false)
        this.$set(this.form.tableData, sessionStorage.getItem('oldindex'), JSON.parse(sessionStorage.getItem('oldRow')))
      }
      sessionStorage.setItem('oldRow', JSON.stringify(row))
      sessionStorage.setItem('oldindex', index)
      this.$set(this.form.tableData[index], 'editShow', true)
    },
    save (index, row) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          editUnit(this.$tools.concatParams(row)).then(() => {
            this.$message.success('单位修改成功')
            this.init()
          })
          this.$set(this.form.tableData[index], 'editShow', false)
          sessionStorage.setItem('oldRow', JSON.stringify(row))
          sessionStorage.setItem('oldindex', index)
          // })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    cancel (index) {
      this.$set(this.form.tableData, index, JSON.parse(sessionStorage.getItem('oldRow')))
      this.$set(this.form.tableData[index], 'editShow', false)
    },
    ensureDelete (siteId) {
      deleteUnit(siteId).then(() => {
        this.$message.success('单位删除成功！')
        this.init()
      })
    },
    headClass () {
      return "text-align:center"
    },
    rowClass () {
      return "text-align:center"
    },
    addUnit () {
      this.$router.push('/system/addunit')
      sessionStorage.setItem('tabNumber', 'first')
    },
    filterType (val) {
      // console.log(val)
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
      }
      if (val.type && val.type.length > 0) {
        // console.log(val.type)
        this.queryParams.sceneId = val.type[0]
        this.init()
      } else {
        this.init()
      }
    }
  }
};
</script>

<style scoped lang="scss">
.unit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    color: #30c6fe;
    font-size: 20px;
    font-weight: bold;
  }
  .selectbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
    ::v-deep .el-input-group {
      background: none;
      // width: 100%;
      .el-input__inner {
        background: none;
        border: 1px solid #30c6fe;
        color: #fff;
      }
      .el-input-group__append {
        background: #1a6899;
        border: 1px solid #30c6fe;
        border-left: none;
        // padding: 0 20px;
        .el-icon-search {
          color: #fff;
        }
      }
    }
  }
  ::v-deep .el-table {
    .el-form-item {
      // padding-bottom: 10px;
      margin-bottom: 0;
      .el-form-item__error {
        // bottom: -10px;
        // top: inherit;
        // left: 10px;
        position: relative;
      }
    }
    .el-input__inner {
      background: none;
      color: #fff;
      border: 1px solid #30c6fe;
    }
    .btns {
      display: inline-block;
      span {
        font-size: 12px;
        color: #1890ff;
        cursor: pointer;
        margin-right: 5px;
      }
    }
    .el-icon-arrow-down {
      color: #1890ff;
      // font-size: 30px;
      font-weight: bold;
    }
  }
}
</style>
